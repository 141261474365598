<template>
  <div class="relative">
    <div v-if="isShow" class="w-full h-full absolute flex justify-center items-center  z-10 ">
      <button @click="emits('submit')" class="bg-white p-5  rounded-lg text-center shadow-md cursor-pointer ">
        <p class="text-2xl"><i class="fa-solid fa-lock"></i></p>
        <slot name="description"></slot>
      </button>
    </div>
   

    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['submit'])
</script>